body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

.gallery-ad {
  max-height: 300px; }

@media (min-width: 500px) {
  .gallery-ad {
    max-height: 200px; } }

@media (min-width: 800px) {
  .gallery-ad {
    max-height: 350px; } }
